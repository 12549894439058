.Smokey {
	background-image: url("space.jpg");
	background-color: #0b0b0b;
	color: red;
	height: 100vh;
	width: 100vw;
	background-position: center;
	background-repeat: repeat;
	overflow: hidden;
}

a.Smokey:active {
	color:#0000EE;
	background-color:black;
	display: "inline";
	padding: 10px;
}

a.Smokey:visited {
	color:orangered;
	background-color:black;
	display: "inline";
	padding: 10px;
}
a.Smokey:hover {
	color:yellow;
	background-color:black;
	display: "inline";
	padding: 10px;
}

.shadow {
	background-color:black;
	display: "inline";
	padding: 10px;
}